/* eslint-disable @typescript-eslint/no-explicit-any */
import type { UseFormReturn } from "react-hook-form";

import { Button } from "@/presentation/components/ui/Button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/presentation/components/ui/Form";
import { Input } from "@/presentation/components/ui/Input";
import Spinner from "@/presentation/components/ui/Spinner";

type LoginFormProps = {
	form: UseFormReturn<
		{
			email: string;
			password: string;
		},
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		any,
		undefined
	>;
	handleSubmitForm: (values: { email: string; password: string }) => void;
	isLoading: boolean;
};

const LoginForm: React.FC<LoginFormProps> = ({ form, isLoading, handleSubmitForm }) => {
	return (
		<div className="bg-white rounded-3xl p-6 shadow-sm w-full max-w-md">
			<p className="text-2xl font-medium text-center flex w-full justify-center">Авторизация</p>
			<Form {...form}>
				<form className="w-full flex flex-col gap-4 mt-4" onSubmit={form.handleSubmit(handleSubmitForm)}>
					<FormField
						control={form.control}
						name="email"
						render={({ field }) => (
							<FormItem>
								<FormLabel className="font-medium text-base">Email</FormLabel>
								<FormControl>
									<Input placeholder="Введите email" {...field} />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<FormField
						control={form.control}
						name="password"
						render={({ field }) => (
							<FormItem>
								<FormLabel className="font-medium text-base">Пароль</FormLabel>
								<FormControl>
									<Input placeholder="Введите пароль" {...field} type="password" />
								</FormControl>
								<FormMessage />
							</FormItem>
						)}
					/>
					<Button
						type="submit"
						className="rounded-xl h-12 bg-accentGreen hover:bg-greenHover transition text-white font-medium gap-2 mt-4"
					>
						{isLoading && <Spinner color="white" />}
						Войти в аккаунт
					</Button>
				</form>
			</Form>
		</div>
	);
};

export default LoginForm;
