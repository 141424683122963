import type { LoginInput, LoginMutation } from "@/data/__generated__/types/generated";
import type { TLoginSchema } from "@/libs/schemas/orderSchema";
import type { MutationFunction } from "@/types";

import { setCookie } from "cookies-next";

type LoginActionsArgs = {
	login: MutationFunction<LoginMutation, { input: LoginInput }>;
};

const LoginActions = ({ login }: LoginActionsArgs) => {
	const handleSubmitForm = (values: TLoginSchema) => {
		login({
			variables: {
				input: {
					...values,
				},
			},
			onCompleted: ({ login }) => {
				if (!login?.accessToken) {
					return;
				}
				setCookie("auth", login.accessToken);
				window.location.href = "/";
			},
		});
	};

	return {
		handleSubmitForm,
	};
};

export default LoginActions;
