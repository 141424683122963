"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import api from "@/data/api";
import { LoginSchema, type TLoginSchema } from "@/libs/schemas/orderSchema";

import View from "./View";
import useActions from "./hooks";

const Login: React.FC = () => {
	const {
		mutate: login,
		result: { loading },
	} = api.user.Login();
	const form = useForm<TLoginSchema>({
		defaultValues: {
			email: "",
			password: "",
		},
		resolver: zodResolver(LoginSchema),
	});

	const { handleSubmitForm } = useActions({
		login,
	});

	return <View form={form} isLoading={loading} handleSubmitForm={handleSubmitForm} />;
};

export default Login;
