/* eslint-disable @typescript-eslint/no-explicit-any */
import type { UseFormReturn } from "react-hook-form";

import LoginForm from "./components/LoginForm";

type LoginViewProps = {
	form: UseFormReturn<
		{
			email: string;
			password: string;
		},
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		any,
		undefined
	>;
	handleSubmitForm: (values: { email: string; password: string }) => void;
	isLoading: boolean;
};

const LoginView: React.FC<LoginViewProps> = ({ form, isLoading, handleSubmitForm }) => {
	return (
		<div className="w-full flex justify-center items-center h-[600px] py-10">
			<LoginForm form={form} isLoading={isLoading} handleSubmitForm={handleSubmitForm} />
		</div>
	);
};

export default LoginView;
