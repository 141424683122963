import { z } from "zod";

export const OrderSchema = z.object({
	name: z.string().min(1, { message: "Заполните поле имя" }),
	phone: z.string().min(1, { message: "Заполните поле телефон" }),
	email: z.union([z.string().email({ message: "Введите корректный email" }), z.literal("")]).optional(),
	comment: z.string(),
});

export const FastOrderSchema = z.object({
	name: z.string().min(1, { message: "Заполните поле имя" }),
	phone: z.string().min(1, { message: "Заполните поле телефон" }),
});

export const LoginSchema = z.object({
	email: z.string().email({ message: "Введите корректный email" }),
	password: z.string().min(1, { message: "Заполните поле пароль" }),
});

export type TFastOrderSchema = z.infer<typeof FastOrderSchema>;

export type TOrderSchema = z.infer<typeof OrderSchema>;

export type TLoginSchema = z.infer<typeof LoginSchema>;
